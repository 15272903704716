import VueRouter from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/index', 
    component: () => import('@/views/layout/index.vue'),
    children: [
      {
        path: '/index',
        component: () => import('@/views/home/index.vue'),
      },
    ]
  },
  {
    path: '/test',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/test/index',
    children: [
      {
        path: '/test/index',
        component: () => import('@/views/test/index.vue'),
      },
    ]
  },
  {
    path: '/product',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/product/index',
    children: [
      {
        path: '/product/index',
        component: () => import('@/views/product/index.vue'),
      },
      {
        path: '/product/test-mode',
        component: () => import('@/views/product/subview/testMode.vue'),
      },
      {
        path: '/product/pseudo-operation',
        component: () => import('@/views/product/subview/pseudoOperation.vue'),
      },
      {
        path: '/product/intelligent-technology',
        component: () => import('@/views/product/subview/intelligentTechnology.vue'),
      },
      {
        path: '/product/intelligent-monitoring',
        component: () => import('@/views/product/subview/intelligentMonitoring.vue'),
      },
      {
        path: '/product/intelligent-detection',
        component: () => import('@/views/product/subview/intelligentDetection.vue'),
      },
    ]
  },
  {
    path: '/achievement',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/achievement/index',
    children: [
      {
        path: '/achievement/index',
        component: () => import('@/views/achievement/index.vue'),
      },
    ]
  },
  {
    path: '/pressCenter',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/pressCenter/index',
    children: [
      {
        path: '/pressCenter/index',
        component: () => import('@/views/pressCenter/index.vue'),
      },
    ]
  },
  {
    path: '/technicalSupport',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/technicalSupport/index',
    children: [
      {
        path: '/technicalSupport/index',
        component: () => import('@/views/technicalSupport/index.vue'),
      },
    ]
  },
  {
    path: '/contactUs',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/contactUs/index',
    children: [
      {
        path: '/contactUs/index',
        component: () => import('@/views/contactUs/index.vue'),
      },
    ]
  },
  {
    path: '/teamIntro',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/teamIntro/index',
    children: [
      {
        path: '/teamIntro/index',
        component: () => import('@/views/team/index.vue'),
      },
    ]
  },
  {
    path: '/moldingCollege',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/moldingCollege/index',
    children: [
      {
        path: '/moldingCollege/index',
        component: () => import('@/views/moldingCollege/index.vue'),
      },
    ]
  },
  {
    path: '/systemDemo',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/systemDemo/index',
    children: [
      {
        path: '/systemDemo/index',
        component: () => import('@/views/systemDemo/index.vue'),
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;